import { css } from '@emotion/react';
import { Box, styled } from '@mui/joy';
import { useMemo } from 'react';

import CrossIcon from '@/assets/icons/cross.svg?react';
import PuRIcon from '@/assets/icons/map-markers/park-and-ride.svg';
import TrainIcon from '@/assets/icons/map-markers/train-station-with-park-and-ride.svg';
import { MapSidebar } from '@/components/map/sidebars/MapSidebar';
import { ParkAndRideStationDetailTable } from '@/components/map/stations/table/ParkAndRideStationDetailTable';
import { TrainStationDetailTable } from '@/components/map/stations/table/TrainStationDetailTable';
import pnrData from '@/data/park-and-rides.json';
import railStationData from '@/data/train-stations.json';
import { IconButtonLink } from '@/router/IconButtonLink';

const StyledIconButtonLink = styled(IconButtonLink)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(1)};
    right: ${theme.spacing(1)};
    z-index: 1;
  `,
) as typeof IconButtonLink;

const ScrollContainer = styled(Box)(
  () => css`
    flex: 1;
    overflow: auto;
  `,
);

const DATA_SOURCES = [
  { data: pnrData, nameKey: 'pr_name', icon: PuRIcon },
  { data: railStationData, nameKey: 'rmv_name', icon: TrainIcon },
];

type MenuProps = {
  id?: string;
};

export function Menu({ id }: MenuProps) {
  const menuInfo = useMemo(() => {
    if (!id) return undefined;

    const matchedFeature = DATA_SOURCES.flatMap(({ data, icon }) =>
      data.features.map((feature) => ({ ...feature, icon })),
    ).find(({ properties }) => properties.gml_id === id);

    if (matchedFeature) {
      return {
        icon: matchedFeature.icon,
        menuData: matchedFeature.properties,
      };
    }

    return undefined;
  }, [id]);

  const matchingStation = railStationData?.features?.find(
    ({ properties }) => properties.rmv_nummer === menuInfo?.menuData.rmv_nummer,
  );

  return (
    <MapSidebar>
      <StyledIconButtonLink
        id="iconButtonLink"
        size="md"
        variant="plain"
        from="/"
        search={(search) => ({ ...search, menu: undefined, id: undefined })}
      >
        <CrossIcon stroke="black" />
      </StyledIconButtonLink>

      <ScrollContainer>
        {menuInfo?.icon === PuRIcon && (
          <ParkAndRideStationDetailTable menuData={menuInfo} stationName={matchingStation?.properties.rmv_name || ''} />
        )}
        {menuInfo?.icon === TrainIcon && <TrainStationDetailTable menuData={menuInfo} />}
      </ScrollContainer>
    </MapSidebar>
  );
}
