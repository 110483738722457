import { Layer, Source } from '@vis.gl/react-maplibre';
import { Polygon } from 'geojson';

import stateGeometry from '@/data/state-polygon.json';

export function MaskingLayer() {
  const polygons = (stateGeometry as Polygon).coordinates;

  return (
    <>
      <Source
        id="hessen"
        type="geojson"
        data={{
          type: 'Polygon',
          coordinates: [
            [
              [180, 90],
              [-180, 90],
              [-180, -90],
              [180, -90],
              [180, 90],
            ],
            ...polygons,
          ],
        }}
      />
      <Layer
        id="ADMIN_LEVELS_LAYER"
        type="fill"
        source="hessen"
        paint={{
          'fill-opacity': 0.125,
          'fill-color': 'black',
        }}
      />
    </>
  );
}
