import { Link, Stack, Typography } from '@mui/joy';

import TrainIcon from '@/assets/icons/map-markers/train-station-with-park-and-ride.svg';
import GrayTrainIcon from '@/assets/icons/map-markers/train-station.svg';
import rmvLogo from '@/assets/logos/rmv.svg';
import { LabelTypography } from '@/components/map/stations/table/LabelTypography';
import { MeansOfTransportTable } from '@/components/map/stations/table/MeansOfTransportTable';
import { StyledTable } from '@/components/map/stations/table/StyledTable';
import { ValueTypography } from '@/components/map/stations/table/ValueTypography';

type RailTableProps = {
  menuData: {
    icon: string;
    menuData: Record<string, any>;
  };
};

export function TrainStationDetailTable({ menuData: { menuData } }: RailTableProps) {
  return (
    <>
      <Stack display="flex" gap={4} alignItems="center" flexDirection="row" justifyContent="flex-start" padding={4}>
        {menuData.anzahl_pr_anlagen >= 1 ? (
          <img alt="Bahnhof Icon" src={TrainIcon} />
        ) : (
          <img alt="Bahnhof Icon" src={GrayTrainIcon} />
        )}
        <Typography level="h4" component="h1">
          {menuData.rmv_name}
        </Typography>
      </Stack>

      <Typography level="body-lg" component="h2" paddingX={4}>
        P+R Anlagen
      </Typography>

      <StyledTable>
        <tbody>
          <tr>
            <td>
              <LabelTypography>Anzahl</LabelTypography>
            </td>
            <td>
              <ValueTypography>{menuData.anzahl_pr_anlagen}</ValueTypography>
            </td>
          </tr>
        </tbody>
      </StyledTable>

      {menuData.anzahl_pr_anlagen >= 1 && (
        <>
          <Typography level="body-lg" component="h2" paddingX={4}>
            Bike + Ride
          </Typography>

          <StyledTable>
            <tbody>
              <tr>
                <td>
                  <LabelTypography>Stellplatzzahl</LabelTypography>
                </td>
                <td>
                  <ValueTypography>{menuData.pr_stellpl}</ValueTypography>
                </td>
              </tr>
              <tr>
                <td>
                  <LabelTypography>davon überdacht</LabelTypography>
                </td>
                <td>
                  <ValueTypography>{menuData.br_stell_dach}</ValueTypography>
                </td>
              </tr>
            </tbody>
          </StyledTable>
        </>
      )}

      <MeansOfTransportTable />

      <Stack gap={2} alignItems="center" padding={4}>
        <img style={{ display: 'block' }} alt="RMV Logo" src={rmvLogo} />

        <Link
          href={`https://www.rmv.de/auskunft/bin/jp/stboard.exe/dn?input=${menuData.rmv_nummer}&boardType=arr&time=now&maxJourneys=50&dateBegin=&dateEnd=&selectDate=today&productsFilter=1111111111111111&start=yes&dirInput=&`}
        >
          Zum Abfahrtsplan für {menuData.rmv_name}
        </Link>
      </Stack>
    </>
  );
}
