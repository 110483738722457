import Fuse from 'fuse.js';

import pnrData from '@/data/park-and-rides.json';
import railStationData from '@/data/train-stations.json';
import { MapLayer } from '@/types/MapLayer';
import { SearchResultType } from '@/types/SearchResultType';

export const combinedData: SearchResultType[] = [
  ...pnrData.features.map(
    (feature) =>
      ({
        type: 'Feature',
        geometry: feature.geometry,
        properties: {
          pr_name: feature.properties.pr_name,
          pr_zufahrtsstr: feature.properties.pr_zufahrtsstr,
          pr_ort: feature.properties.pr_ort,
          gml_id: feature.properties.gml_id,
          mapLayer: MapLayer.PARK_AND_RIDE,
        },
      }) as SearchResultType,
  ),
  ...railStationData.features.map(
    (feature) =>
      ({
        type: 'Feature',
        geometry: feature.geometry,
        properties: {
          rmv_name: feature.properties.rmv_name,
          gml_id: feature.properties.gml_id,
          anzahl_pr_anlagen: feature.properties.anzahl_pr_anlagen,
          mapLayer: MapLayer.TRAIN_STATION,
        },
      }) as SearchResultType,
  ),
];

export const fuse = new Fuse<SearchResultType>(combinedData, {
  keys: ['properties.pr_name', 'properties.pr_zufahrtsstr', 'properties.pr_ort', 'properties.rmv_name'],
});
