import { css } from '@emotion/react';
import { Button, Stack, styled, ToggleButtonGroup } from '@mui/joy';
import { getRouteApi } from '@tanstack/react-router';

import { SearchBar } from '@/components/SearchBar';
import { I18N_DE } from '@/i18n/de';
import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';
import { Route } from '@/routes';
import { MapLayer } from '@/types/MapLayer';

const Root = styled(Stack)(
  ({ theme }) => css`
    position: fixed;
    z-index: 1;
    top: ${theme.spacing(2)};
    left: ${theme.spacing(2)};
  `,
);

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => css`
    ${theme.breakpoints.down(MAIN_BREAKPOINT)} {
      display: none;
    }
  `,
);

const routeApi = getRouteApi('/');

export function MenuBar() {
  const { layers } = Route.useSearch();
  const navigate = routeApi.useNavigate();

  return (
    <Root direction="row" gap={2}>
      <StyledToggleButtonGroup value={layers} color="primary" size="md" variant="solid" spacing={2}>
        {Object.values(MapLayer).map((mapLayer) => (
          <Button
            value={mapLayer}
            key={mapLayer}
            onClick={() =>
              navigate({
                search: (prev) => ({
                  layers: prev?.layers?.includes(mapLayer)
                    ? prev.layers.filter((layer) => layer !== mapLayer)
                    : [...(prev?.layers || []), mapLayer],
                }),
              })
            }
          >
            {I18N_DE.MapLayer[mapLayer]}
          </Button>
        ))}
      </StyledToggleButtonGroup>

      <SearchBar />
    </Root>
  );
}
