import { Box, Link, Stack, Typography } from '@mui/joy';

import PuRIcon from '@/assets/icons/map-markers/park-and-ride.svg';
import rmvLogo from '@/assets/logos/rmv.svg';
import { LabelTypography } from '@/components/map/stations/table/LabelTypography';
import { MeansOfTransportTable } from '@/components/map/stations/table/MeansOfTransportTable';
import { StyledTable } from '@/components/map/stations/table/StyledTable';
import { ValueTypography } from '@/components/map/stations/table/ValueTypography';

type PurTableProps = {
  menuData: {
    icon: string;
    menuData: Record<string, any>;
  };
  stationName: string;
};

export function ParkAndRideStationDetailTable({ menuData: { menuData }, stationName }: PurTableProps) {
  return (
    <>
      <Stack display="flex" gap={4} alignItems="center" flexDirection="row" justifyContent="flex-start" padding={4}>
        <img alt="Park + Ride Icon" src={PuRIcon} />
        <Typography level="body-lg" fontWeight={600}>
          {menuData.pr_name}
        </Typography>
      </Stack>

      <Typography level="body-lg" component="h2" paddingX={4}>
        Park + Ride
      </Typography>

      <StyledTable>
        <tbody>
          <tr>
            <td colSpan={2}>
              <LabelTypography>Stellplatzzahl:</LabelTypography>
            </td>
          </tr>
          <tr>
            <td>
              <LabelTypography>Stellplatzzahl</LabelTypography>
            </td>
            <td>
              <ValueTypography>{menuData.pr_stellpl}</ValueTypography>
            </td>
          </tr>
          <tr>
            <td>
              <LabelTypography>Behindertenparkplätze</LabelTypography>
            </td>
            <td>
              <ValueTypography>
                {menuData.pr_behinderten_stellpl === '' ? '0' : menuData.pr_behinderten_stellpl}
              </ValueTypography>
            </td>
          </tr>
          <tr>
            <td>
              <LabelTypography>Barrierefreier Zugang</LabelTypography>
            </td>
            <td>
              <ValueTypography>
                {menuData.pr_zugang_barrierefrei === '' ? 'Nein' : menuData.pr_zugang_barrierefrei}
              </ValueTypography>
            </td>
          </tr>
          {menuData.pr_auslastung_text && (
            <tr>
              <td colSpan={2}>
                <ValueTypography paddingTop={1}>{menuData.pr_auslastung_text}</ValueTypography>
              </td>
            </tr>
          )}
        </tbody>
      </StyledTable>

      {(menuData.pr_einschr_art !== '' || menuData.pr_organisation || menuData.pr_sonstige_merkmale !== '') && (
        <StyledTable>
          <tbody>
            {menuData.pr_organisation && (
              <tr>
                <td colSpan={2}>
                  <ValueTypography>{menuData.pr_organisation}</ValueTypography>
                </td>
              </tr>
            )}
            {menuData.pr_sonstige_merkmale !== '' && (
              <tr>
                <td colSpan={2}>
                  <ValueTypography>{menuData.pr_sonstige_merkmale}</ValueTypography>
                </td>
              </tr>
            )}
            {menuData.pr_einschr_art && (
              <tr>
                <td>
                  <ValueTypography>{menuData.pr_einschr_art}</ValueTypography>
                </td>
                <td>
                  <ValueTypography>{menuData.pr_einschr_art}</ValueTypography>
                </td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      )}

      {menuData.publ_nutzer !== '' && (
        <StyledTable>
          <tbody>
            <tr>
              <td colSpan={2}>
                <ValueTypography>{menuData.publ_nutzer}</ValueTypography>
              </td>
            </tr>
          </tbody>
        </StyledTable>
      )}

      <Box paddingX={4} marginBottom={4}>
        <Typography level="body-lg" component="h2">
          Adresse
        </Typography>
        <Typography>
          {menuData.pr_zufahrtsstr}
          <br />
          {menuData.pr_plz} {menuData.pr_ort}
        </Typography>
      </Box>

      <StyledTable>
        <tbody>
          <tr>
            <td>
              <LabelTypography>Bewirtschaftung</LabelTypography>
            </td>
            <td>
              <ValueTypography textAlign="end">{menuData.pr_bewirtschaftung}</ValueTypography>
            </td>
          </tr>
          <tr>
            <td>
              <LabelTypography>Öffnungszeiten</LabelTypography>
            </td>
            <td>
              <ValueTypography>24/7</ValueTypography>
            </td>
          </tr>
        </tbody>
      </StyledTable>

      <MeansOfTransportTable />

      <Stack gap={2} alignItems="center" padding={4}>
        <img style={{ display: 'block' }} alt="RMV Logo" src={rmvLogo} />

        <Link
          href={`https://www.rmv.de/auskunft/bin/jp/stboard.exe/dn?input=${menuData.rmv_nummer}&boardType=arr&time=now&maxJourneys=50&dateBegin=&dateEnd=&selectDate=today&productsFilter=1111111111111111&start=yes&dirInput=&`}
        >
          Zum Abfahrtsplan für {stationName}
        </Link>
      </Stack>
    </>
  );
}
