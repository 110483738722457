import { styled, Table } from '@mui/joy';
import { css } from '@mui/system';

export const StyledTable = styled(Table)(
  ({ theme }) => css`
    padding-left: ${theme.spacing(4)};
    padding-right: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(4)};

    & thead th:first-of-type {
      padding-left: 0;
    }

    & thead th:last-of-type {
      padding-right: 0;
    }

    & tbody td:first-of-type {
      padding-left: 0;
    }

    & tbody td:last-of-type {
      padding-right: 0;
    }
  `,
);
