import { css } from '@emotion/react';
import { Sheet, styled } from '@mui/joy';

import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';
import { transientOptions } from '@/utils/transient-options';

export const MapSidebar = styled(
  Sheet,
  transientOptions,
)(
  ({ theme }) => css`
    background-color: ${theme.vars.palette.background.body};
    display: flex;
    flex-direction: column;
    left: 0;
    bottom: 0;
    z-index: 0;
    transition: height 0.3s ease-in-out;
    height: 40dvh;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      width: ${theme.spacing(58)};
      flex-shrink: 0;
      z-index: 1;
      height: 100%;
      max-height: calc(100vh - ${theme.spacing(9)});
    }
  `,
);
