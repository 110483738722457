import { Typography } from '@mui/joy';

import undergroundIcon from '@/assets/icons/means-of-transportation/underground.svg';
import { StyledTable } from '@/components/map/stations/table/StyledTable';

export function MeansOfTransportTable() {
  return (
    <>
      <Typography level="body-lg" component="h2" paddingX={4}>
        Verbindungen
      </Typography>

      <StyledTable>
        <tbody>
          <tr>
            <td style={{ width: '64px' }}>
              <img alt="U-Bahn" src={undergroundIcon} style={{ height: 'auto', width: '32px' }} />
            </td>
            <td>U2</td>
            <td>Bad Homburg Gonzenheim - F.-Hauptwache - F.-Südbahnhof</td>
          </tr>
          <tr>
            <td style={{ width: '64px' }}>
              <img alt="U-Bahn" src={undergroundIcon} style={{ height: 'auto', width: '32px' }} />
            </td>
            <td>U3</td>
            <td>Bad Homburg Gonzenheim - F.-Hauptwache - F.-Südbahnhof</td>
          </tr>
        </tbody>
      </StyledTable>
    </>
  );
}
