import { css } from '@emotion/react';
import { Box, styled } from '@mui/joy';
import { AttributionControl, LngLatBoundsLike, Map } from '@vis.gl/react-maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';

import Logo from '@/assets/logos/park-ride-hessen.png';
import { MaskingLayer } from '@/components/map/layers/MaskingLayer';
import { StationLayer } from '@/components/map/layers/StationLayer';
import { useScreenSize } from '@/hooks/useScreenSize';
import { MAP_MAX_ZOOM } from '@/modules/MAP_MAX_ZOOM';

export const BOUNDS = [8.7090022 - 0.1, 50.1141545 - 0.1, 8.7090022 + 0.1, 50.1141545 + 0.1] as LngLatBoundsLike;

const LogoWrapper = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(2)};
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
    background-color: rgba(255, 255, 255, 0.75);
    height: ${theme.spacing(11)};
    width: ${theme.spacing(40)};
    ${theme.breakpoints.up('xl')} {
      display: flex;
    }
  `,
);

export function BaseMap() {
  const { isDesktop } = useScreenSize();

  return (
    <Map
      id="map"
      dragRotate={false}
      touchPitch={false}
      bearing={0}
      pitch={0}
      mapStyle="https://sgx.geodatenzentrum.de/gdz_basemapde_vektor/styles/bm_web_gry.json"
      initialViewState={{ bounds: BOUNDS }}
      minZoom={5}
      maxZoom={MAP_MAX_ZOOM}
      attributionControl={false}
    >
      <AttributionControl position={isDesktop ? 'top-right' : 'bottom-right'} compact />
      <MaskingLayer />
      <StationLayer />

      <LogoWrapper borderRadius="lg">
        <img alt="Park+Ride Hessen" style={{ height: 120 }} src={Logo} />
      </LogoWrapper>
    </Map>
  );
}
