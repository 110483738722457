import { useTheme } from '@mui/joy';
import { getRouteApi } from '@tanstack/react-router';
import { Layer, LngLatLike, Source } from '@vis.gl/react-maplibre';
import { Feature, FeatureCollection } from 'geojson';
import maplibregl, { GeoJSONSource, MapLayerEventType } from 'maplibre-gl';
import { useCallback, useMemo } from 'react';

import clusterIcon from '@/assets/icons/map-markers/cluster.svg?url';
import parkAndRideIcon from '@/assets/icons/map-markers/park-and-ride.svg?url';
import selectedIcon from '@/assets/icons/map-markers/selected.svg?url';
import trainStationWithParkAndRideIcon from '@/assets/icons/map-markers/train-station-with-park-and-ride.svg?url';
import trainStationIcon from '@/assets/icons/map-markers/train-station.svg?url';
import parkAndRidesData from '@/data/park-and-rides.json';
import trainStationsData from '@/data/train-stations.json';
import { useMapImages } from '@/hooks/useMapImages';
import { useMapOnEvent } from '@/hooks/useMapOnEvent';
import { useScreenSize } from '@/hooks/useScreenSize';
import { MAP_FLY_TO_ZOOM } from '@/modules/MAP_FLY_TO_ZOOM';
import { MAP_MARKER_MIN_ZOOM } from '@/modules/MAP_MARKER_MIN_ZOOM';
import { MAP_MAX_ZOOM } from '@/modules/MAP_MAX_ZOOM';
import { Route } from '@/routes';
import { MapLayer } from '@/types/MapLayer';

export const STATIONS_SOURCE = 'stations';
const STATION_CLUSTER_LAYER = `${STATIONS_SOURCE}-cluster-large`;
const STATION_CLUSTER_COUNT_LAYER = `${STATIONS_SOURCE}-cluster-count-layer`;
export const STATIONS_ICON_LAYER = `${STATIONS_SOURCE}-station-icon-layer`;
const STATIONS_ACTIVE_LAYER = `${STATIONS_SOURCE}-station-active-layer`;
const routeApi = getRouteApi('/');

export function StationLayer() {
  const theme = useTheme();
  const { layers, id } = Route.useSearch();
  const navigate = routeApi.useNavigate();
  const { isDesktop } = useScreenSize();

  const data: FeatureCollection = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: [
        ...((layers?.includes('TRAIN_STATION') ? trainStationsData.features : []) as Feature[]).map((feature) => ({
          ...feature,
          properties: { ...feature.properties, type: MapLayer.TRAIN_STATION },
        })),
        ...((layers?.includes('PARK_AND_RIDE') ? parkAndRidesData.features : []) as Feature[]).map((feature) => ({
          ...feature,
          properties: { ...feature.properties, type: MapLayer.PARK_AND_RIDE },
        })),
      ],
    }),
    [layers],
  );

  useMapImages({
    images: [
      {
        name: STATION_CLUSTER_LAYER,
        url: clusterIcon,
        width: 40,
        height: 40,
      },
      {
        name: parkAndRideIcon,
        url: parkAndRideIcon,
        width: 36,
        height: 36,
      },
      {
        name: trainStationWithParkAndRideIcon,
        url: trainStationWithParkAndRideIcon,
        width: 36,
        height: 36,
      },
      {
        name: trainStationIcon,
        url: trainStationIcon,
        width: 36,
        height: 36,
      },
      {
        name: STATIONS_ACTIVE_LAYER,
        url: selectedIcon,
        width: 40,
        height: 40,
      },
    ],
  });

  const handleMapClick = useCallback(
    ({ target, features }: MapLayerEventType['click']) => {
      const feature = features?.[0];

      if (feature?.geometry.type === 'Point' && feature?.properties?.gml_id) {
        navigate({ search: (search) => ({ ...search, id: feature.properties.gml_id, menu: true }) });

        const [lng, lat] = feature.geometry.coordinates || [];
        if (lng !== undefined && lat !== undefined) {
          target.flyTo({
            center: [lng, lat],
            ...(target.getZoom() < MAP_FLY_TO_ZOOM - 2 && { zoom: MAP_FLY_TO_ZOOM - 2 }),
          });
        }
      }
    },
    [navigate],
  );

  useMapOnEvent({
    type: 'click',
    layer: STATIONS_ICON_LAYER,
    listener: handleMapClick,
  });

  useMapOnEvent({
    type: 'click',
    layer: STATIONS_ICON_LAYER,
    listener: handleMapClick,
  });

  const handleClusterClick = useCallback(
    async ({ target, features }: MapLayerEventType['click']) => {
      const clusterId = features?.[0]?.properties?.cluster_id;

      const leaves = (await (target.getSource(STATIONS_SOURCE) as GeoJSONSource).getClusterLeaves(
        clusterId,
        1000000,
        0,
      )) as any[];

      const bounds = leaves.reduce(
        (currentBounds, feature) => currentBounds.extend(feature.geometry.coordinates as LngLatLike),
        new maplibregl.LngLatBounds(),
      );

      target.fitBounds(bounds, {
        padding: {
          top: isDesktop ? 146 : 128,
          right: isDesktop ? 64 : 32,
          bottom: isDesktop ? 88 : 64,
          left: isDesktop ? 64 : 32,
        },
      });
    },
    [isDesktop],
  );

  useMapOnEvent({
    type: 'click',
    layer: STATION_CLUSTER_LAYER,
    listener: handleClusterClick,
  });

  return (
    <Source
      id={STATIONS_SOURCE}
      type="geojson"
      data={data as FeatureCollection}
      promoteId="id"
      cluster
      clusterRadius={48}
      clusterMaxZoom={MAP_FLY_TO_ZOOM}
      maxzoom={MAP_MAX_ZOOM}
    >
      <Layer
        id={STATION_CLUSTER_LAYER}
        type="symbol"
        filter={['has', 'point_count']}
        layout={{
          'icon-image': STATION_CLUSTER_LAYER,
          'icon-allow-overlap': true,
          'icon-anchor': 'center',
        }}
      />
      <Layer
        id={STATION_CLUSTER_COUNT_LAYER}
        type="symbol"
        filter={['has', 'point_count']}
        layout={{
          'text-allow-overlap': true,
          'text-field': '{point_count_abbreviated}',
          'text-size': 18,
          'text-font': ['Noto Sans Bold'],
        }}
        paint={{
          'text-color': theme.palette.neutral[50],
        }}
      />

      <Layer
        id={STATIONS_ICON_LAYER}
        type="symbol"
        maxzoom={MAP_MARKER_MIN_ZOOM}
        layout={{
          'icon-image': [
            'match',
            ['get', 'type'],
            MapLayer.PARK_AND_RIDE,
            parkAndRideIcon,
            MapLayer.TRAIN_STATION,
            ['case', ['==', ['get', 'anzahl_pr_anlagen'], '0'], trainStationIcon, trainStationWithParkAndRideIcon],
            'default-icon',
          ],
          'icon-allow-overlap': true,
        }}
      />

      <Layer
        id={STATIONS_ACTIVE_LAYER}
        beforeId={STATIONS_ICON_LAYER}
        type="symbol"
        filter={['==', ['get', 'gml_id'], id || '']}
        layout={{
          'icon-image': STATIONS_ACTIVE_LAYER,
          'icon-size': 1.125,
          'icon-allow-overlap': true,
          'icon-anchor': 'center',
        }}
      />
    </Source>
  );
}
