import { css } from '@emotion/react';
import { Box, Stack, styled } from '@mui/joy';
import { createFileRoute } from '@tanstack/react-router';
import { MapProvider } from '@vis.gl/react-maplibre';
import { z } from 'zod';

import { BaseMap } from '@/components/BaseMap';
import { Footer } from '@/components/Footer';
import { Menu } from '@/components/map/menu/Menu';
import { MenuBar } from '@/components/navigations/MenuBar';
import { MobileMenuBar } from '@/components/navigations/MobileMenuBar';
import { useScreenSize } from '@/hooks/useScreenSize';
import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';
import { MapLayer } from '@/types/MapLayer';

const Root = styled(Stack)(
  () => css`
    width: 100vw;
    height: 100vh;
  `,
);

const MapWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      flex-direction: row;
      width: 100vw;
      height: 100%;
    }
  `,
);

const FooterWrapper = styled(Box)(
  ({ theme }) => css`
    display: none;
    bottom: 0;
    width: 100%;
    background-color: ${theme.palette.common.white};

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      display: flex;
    }
  `,
);

const mapLayerValues = Object.values(MapLayer) as [string, ...string[]];

const indexSchema = z.object({
  menu: z.boolean().optional(),
  search: z.boolean().optional(),
  id: z.string().optional(),
  layers: z.array(z.enum(mapLayerValues).catch('layers')).optional(),
});

export const Route = createFileRoute('/')({
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  component: Index,
  validateSearch: indexSchema,
});

function Index() {
  const { isDesktop } = useScreenSize();
  const { menu, id } = Route.useSearch();

  return (
    <Root direction="column">
      <Box flex={1}>
        <MapProvider>
          <MenuBar />
          {!isDesktop && <MobileMenuBar />}

          <MapWrapper flex={1}>
            <BaseMap />

            {menu && <Menu id={id} />}
          </MapWrapper>
        </MapProvider>
      </Box>

      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Root>
  );
}
