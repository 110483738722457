import { css } from '@emotion/react';
import { Box, ButtonGroup, Drawer, IconButton, styled } from '@mui/joy';
import React from 'react';

import LayersIcon from '@/assets/icons/layers.svg?react';
import MenuIcon from '@/assets/icons/menu.svg?react';
import { Footer } from '@/components/Footer';
import { LayersModal } from '@/components/modals/LayersModal';
import { Route } from '@/routes';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    width: ${theme.spacing(5)};
    height: ${theme.spacing(0.75)};
    border-radius: 3px;
    position: absolute;
    top: ${theme.spacing(1)};
    left: calc(50% - 15px);
    z-index: 1;
    background-color: #c7c7cc;
    cursor: pointer;
  `,
);

const StyledButtonGroup = styled(ButtonGroup)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
);

export function MobileMenuBar() {
  const [layerDrawerIsOpen, setLayerDrawerIsOpen] = React.useState(false);
  const [footerDrawerIsOpen, setFooterDrawerIsOpen] = React.useState(false);
  const { search } = Route.useSearch();

  const toggleDrawer =
    (setOpen: React.Dispatch<React.SetStateAction<boolean>>, inOpen: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(inOpen);
    };

  return (
    <>
      {!search && (
        <StyledButtonGroup>
          <IconButton
            sx={{ padding: 2.125 }}
            size="lg"
            variant="soft"
            color="primary"
            onClick={toggleDrawer(setLayerDrawerIsOpen, true)}
          >
            <LayersIcon />
          </IconButton>
          <IconButton
            sx={{ padding: 2.125 }}
            size="lg"
            variant="soft"
            color="primary"
            onClick={toggleDrawer(setFooterDrawerIsOpen, true)}
          >
            <MenuIcon />
          </IconButton>
        </StyledButtonGroup>
      )}
      <Box sx={{ display: 'flex' }}>
        <Drawer
          open={footerDrawerIsOpen}
          id="footer-drawer"
          onClose={toggleDrawer(setFooterDrawerIsOpen, false)}
          component="div"
          anchor="bottom"
          invertedColors={false}
          variant="solid"
          slotProps={{
            backdrop: { sx: { backdropFilter: 'none', background: 'unset' } },
            content: {
              sx: {
                background: 'white',
                alignItems: 'center',
                overflow: 'auto',
                scrollbarWidth: 'none',
                height: 'auto',
                padding: 2,
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              },
            },
          }}
        >
          <StyledBox onClick={toggleDrawer(setFooterDrawerIsOpen, false)} />
          <Footer />
        </Drawer>
      </Box>
      <LayersModal layerDrawerIsOpen={layerDrawerIsOpen} onClose={() => setLayerDrawerIsOpen(false)} />
    </>
  );
}
