import { extendTheme } from '@mui/joy';

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          '50': '#f0f7ff',
          '100': '#dfeeff',
          '200': '#b9defe',
          '300': '#7bc4fe',
          '400': '#34a6fc',
          '500': '#0a8bed',
          '600': '#006ccb',
          '700': '#0057a6',
          '800': '#054a87',
          '900': '#0a3e70',
        },
      },
    },
  },
});
