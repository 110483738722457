import { styled, Typography } from '@mui/joy';
import { css } from '@mui/system';

export const ValueTypography = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.neutral.plainColor};
    font-size: ${theme.spacing(1.75)};
    font-weight: 600;
    text-align: right;
  `,
);
