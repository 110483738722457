import { css } from '@emotion/react';
import { Box, IconButton, styled } from '@mui/joy';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { getRouteApi } from '@tanstack/react-router';
import { useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';

import { MapGeocoderAutocomplete } from '@/components/map/MapGeocoderAutocomplete';
import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';
import { Route } from '@/routes';

const ButtonWrapper = styled(Box)(
  ({ theme }) => css`
    z-index: 1;
    visibility: hidden;
    transform: translateX(0) translateY(${theme.spacing(2)});
    opacity: 0;

    transition:
      transform 300ms,
      opacity 300ms;
  `,
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    ${theme.breakpoints.down(MAIN_BREAKPOINT)} {
      padding: ${theme.spacing(1.625)};
    }
  `,
);

const routeApi = getRouteApi('/');

export function SearchBar() {
  const { search } = Route.useSearch();
  const navigate = routeApi.useNavigate();

  const geocoderRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (search) {
      setTimeout(() => geocoderRef.current?.focus(), 300);
    }
  }, [search]);

  return (
    <Box position="relative">
      <Transition in={!search} timeout={300}>
        {(state) => (
          <ButtonWrapper
            sx={{
              ...(state !== 'exited' && { visibility: 'visible' }),
              ...(['entering', 'entered'].includes(state) && {
                transform: 'translateY(0)',
                opacity: 1,
              }),
            }}
          >
            <StyledIconButton
              color="primary"
              variant="solid"
              size="lg"
              onClick={() => {
                navigate({
                  search: (prev) => ({
                    ...prev,
                    search: prev.search ? undefined : true,
                  }),
                });
              }}
            >
              <MagnifyingGlass size={28} />
            </StyledIconButton>
          </ButtonWrapper>
        )}
      </Transition>

      <Transition in={search} timeout={300}>
        {(state) => (
          <MapGeocoderAutocomplete
            ref={geocoderRef}
            sx={{
              ...(state !== 'exited' && { visibility: 'visible' }),
              ...(['entering', 'entered'].includes(state) && {
                transform: 'translateY(0)',
                opacity: 1,
              }),
            }}
            onClose={() => navigate({ search: (prev) => ({ ...prev, search: undefined }) })}
          />
        )}
      </Transition>
    </Box>
  );
}
