import { css } from '@emotion/react';
import { Box, Button, styled } from '@mui/joy';
import { createFileRoute, Link, Outlet, ScrollRestoration } from '@tanstack/react-router';

import Logo from '@/assets/logos/park-ride-hessen.png';
import { Footer } from '@/components/Footer';
import { HeaderList } from '@/components/navigations/HeaderList';
import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';

const Root = styled(Box)(
  () => css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  `,
);

const LegalWrapper = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(12)};

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      padding: ${theme.spacing(6)} ${theme.spacing(12)};
    }
  `,
);

const Header = styled(Box)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    z-index: 1100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.palette.common.white};
    box-shadow: 0 3px 6px #00000029;
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
    flex-direction: row-reverse;
    gap: ${theme.spacing(2)};

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr;

      padding: ${theme.spacing(0)} ${theme.spacing(12)};
    }
  `,
) as typeof Box;

const FooterWrapper = styled(Box)(
  ({ theme }) => css`
    margin-top: auto;
    padding-bottom: ${theme.spacing(2)};

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      padding: 0;
    }
  `,
);

function LegalLayout() {
  return (
    <Root>
      <Header component="header">
        <HeaderList>
          <Button color="primary" variant="plain" role="menuitem" component={Link} to="/">
            Startseite
          </Button>
        </HeaderList>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Link to="/">
            <img alt="Park+Ride Hessen" style={{ height: 120 }} src={Logo} />
          </Link>
        </Box>
      </Header>
      <LegalWrapper>
        <ScrollRestoration />
        <Outlet />
      </LegalWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Root>
  );
}

export const Route = createFileRoute('/_legal')({
  component: LegalLayout,
});
